import React, {useContext} from "react";
import {
    FilterBar,
    FilterBarChip,
    FilterBarTextChip,
    FilterBarTextField,
    FilterDateInput, FilterDateSelect,
    FilterSelect,
} from "./styles";
import {
    Container,
    InputAdornment,
    Stack,
    MenuItem,
    popoverClasses,
    ListItemText,
    Checkbox,
    ListSubheader,
    Divider
} from "@mui/material";
import {Close, FilterAltOutlined, NoteAddOutlined, SearchOutlined,} from "@mui/icons-material";
import {
    CaseListFilterContext,
    FilterDateType,
} from "../../../context/CaseListFilters";
import dayjs from "dayjs";
import JourneyContext from "../../../context/JourneyContext";
import { useBrand } from "../../../hooks/Brand/useBrand";
import ExportCsvButton from "../../../components/common/ExportCsvButton";
import {CaseResponse, getDueDate} from "../../../models/responses/Cases/CaseResponse";
import {formatDate, formatDateTime, formatNonUSDate, formatNonUSDateTime} from "../../../utils/DateUtils/dateUtils";
import {getCaseStatusRender} from "../CaseListStatusBadge";
import {Location} from "../../../models/responses/Location";

type CaseFilterBarProps = {
    cases: CaseResponse[];
};

const CaseFilterBar = ({
  cases,
}: CaseFilterBarProps) => {
  const {currentJourney} = useContext(JourneyContext);
  const {
      filterStatuses,
      filterText,
      filterDate,
      filterDateEnd,
      dateType,
      handleFilterStatusSelectChange,
      handleRemoveFilterStatus,
      setFilterText,
      setFilterDate,
      setFilterDateEnd,
      setDateType,
  } = useContext(CaseListFilterContext);

  const {data : brand} = useBrand();
  const isEverifyEnabled = brand?.i9Config?.everifyEnabled;
  const isI9 =  currentJourney?.journeyType.startsWith("I9");
  const isPrescient = currentJourney?.journeyType === "PRESCIENT";
  const configFilterList = currentJourney && Object.entries(currentJourney?.uiConfig.filter)
      .map(([key, value]) => ({value: key, label: value.label, group: value.group? value.group : ""}));
  const filterList = isI9 ? configFilterList?.filter(itm => isEverifyEnabled || itm.label !== "E-Verify") : configFilterList;

  const getLocationForId = (locationId: number):Location | null => {
        if(locationId === null || locationId === undefined) {
            return null;
        }
        return (currentJourney?.locations || []).find(location => location.id === locationId) || null;
  }

  // eslint-disable-next-line
  const csvCases = (cases: CaseResponse[]): any[]  => cases.sort(
      (a, b) => a.createdAt > b.createdAt? -1 : 1).map((caseItem) => {
        const renderedCase = isI9 ? {
            candidateRef: caseItem.consumerReference,
            firstName: caseItem.givenName,
            lastName: caseItem.familyName,
            location: getLocationForId(caseItem.locationId)?.name || caseItem.country,
            startDate: formatDate(caseItem.startDate || ""),
            sectionStatus: getCaseStatusRender(caseItem.status, caseItem.additionalData.i9_section_2_invite_timestamp !== undefined).label,
            dueDate: getDueDate(caseItem).replaceAll(',', ''),
            createDate: formatDateTime(caseItem.createdAt).replaceAll(',', '')
        } : {
            candidateRef: caseItem.consumerReference,
            firstName: caseItem.givenName,
            lastName: caseItem.familyName,
            location: getLocationForId(caseItem.locationId)?.name || caseItem.country,
            startDate: formatNonUSDate(caseItem.startDate || ""),
            status: getCaseStatusRender(caseItem.status, caseItem.additionalData.i9_section_2_invite_timestamp !== undefined).label,
            createDate: formatNonUSDateTime(caseItem.createdAt)
        };
        if (isEverifyEnabled) {
            return {
                ...renderedCase,
                everifyCaseNumber: caseItem.additionalData.everify_case_number
            }
        } else {
            return renderedCase;
        }

  });

  type FilterListProps = {
    label: string,
    value: string,
    group: string,
  }

  const eVerifyFilterList: FilterListProps[]= [
    {
      label: 'Final Nonconfirmation',
      value: 'FINAL_NONCONFIRMATION',
      group: 'E-VERIFY'
    },
    {
      label: 'Tentative Nonconfirmation',
      value: 'TENTATIVE_NONCONFIRMATION',
      group: 'E-VERIFY'
    },
    {
      label: 'Employment Authorized',
      value: 'EMPLOYMENT_AUTHORIZED',
      group: 'E-VERIFY'
    },
    {
      label: 'Case Creation Failed',
      value: 'CASE_CREATION_FAILED',
      group: 'E-VERIFY'
    }
  ]

  const summaryFilterList = isEverifyEnabled ? filterList? [...filterList, ...eVerifyFilterList] : eVerifyFilterList : filterList;
  const filterStatusLabelMap = summaryFilterList && Object.fromEntries(summaryFilterList.map(({value, label}) => ([value, label])));

  const groupedFilterList: { [s: string]: FilterListProps[] } | undefined = summaryFilterList?.reduce((groupedList, filter) => {
      const key = filter.group as keyof typeof groupedList;
      if (!groupedList[key]) {
          // eslint-disable-next-line
          // @ts-ignore
          groupedList[key] = [];
      }
      // eslint-disable-next-line
      // @ts-ignore
      groupedList[key].push(filter);
        return groupedList;
    }, {});

  const isGroupName = (group: string): boolean | string  => {
      return group && group !== "" && !group.includes("no-description");
  }

    const renderFilterGroup = (name: string, group: FilterListProps[], isLast: boolean) => {
      const divider = <Divider/>;
        const items = group.map(g => {
            return (
                <MenuItem key={g.value} value={g.value} style={{paddingLeft: "40px"}}>
                    <ListItemText primary={g.label} style={{minWidth: "250px"}}/>
                    <Checkbox color={"secondary"} checked={filterStatuses.indexOf(g.value) > -1} />
                </MenuItem>
            );
        });

        if (isLast) {
            if (isGroupName(name)) {
                // eslint-disable-next-line
                return [<ListSubheader style={{textTransform: "uppercase"}}>{name}</ListSubheader>, items];
            } else {
                // eslint-disable-next-line
                return [items];
            }
        } else {
            if (isGroupName(name)) {
                // eslint-disable-next-line
                return [<ListSubheader style={{textTransform: "uppercase"}}>{name}</ListSubheader>, items, divider];
            } else {
                // eslint-disable-next-line
                return [items, divider];
            }
        }
    };

    return (
    <Container sx={{ flexGrow: 1, p: 3 }} maxWidth={false}>
      <FilterBar>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <FilterSelect
                IconComponent={FilterAltOutlined}
                value={filterStatuses}
                onChange={handleFilterStatusSelectChange}
                multiple
                renderValue={()=>null}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  sx: {
                    [`&.${popoverClasses.root}`]: {
                      backgroundColor: "#000A"
                    },
                  }
                }}
            >
                { !isI9 && <ListSubheader style={{fontWeight: "bold", fontSize: "13pt", color: "#000"}}>{'Filter'}</ListSubheader>}
                {
                    groupedFilterList && Object.entries(groupedFilterList)
                        .map(([key, values], index) => renderFilterGroup(key, values,
                        index === Object.entries(groupedFilterList).length - 1))
                }
            </FilterSelect>
            {
              filterStatuses.map(filterStatus => <FilterBarTextChip
                  key={`${filterStatus}-chip`}
                  label={filterStatusLabelMap?.[filterStatus]}
                  onDelete={() => handleRemoveFilterStatus(filterStatus) }
                  deleteIcon={<Close />}
              />)
            }
          </Stack>
          <Stack direction="row" spacing={2} alignItems="center">
              <FilterDateSelect value={dateType} onChange={(evt) => {
                  setDateType(evt.target.value as FilterDateType)
              }} sx={{marginRight: '10px'}}>
                  {isI9 && <MenuItem value={"dueDate"}>Due Date</MenuItem> }
                  {!isPrescient && <MenuItem value={"appointmentDate"}>Appointment Date</MenuItem>}
                  <MenuItem value={"startDate"}>Start Date</MenuItem>
                  <MenuItem value={"createdAt"}>Create Date</MenuItem>
              </FilterDateSelect>
              <FilterDateInput
                  format={isI9? undefined : "YYYY/MM/DD"}
                  value={
                    filterDate.length === 0 ? null : dayjs(filterDate)
                  }
                  // eslint-disable-next-line
                  onChange={(date: any) => {
                      if (date) {
                          setFilterDate(date.toISOString());
                      } else {
                          setFilterDate("")
                      }
                  }}
                  slotProps={{
                      textField: {placeholder: "From"},
                      actionBar: {
                          actions: ["clear"],
                          sx: {
                              background: "#00255440",
                          },
                      },
                  }}
              />
              <FilterDateInput
                  format={isI9? undefined : "YYYY/MM/DD"}
                  value={
                      filterDateEnd.length === 0 ? null : dayjs(filterDateEnd)
                  }
                  // eslint-disable-next-line
                  onChange={(date: any) => {
                      if (date) {
                          setFilterDateEnd(date.toISOString());
                      } else {
                          setFilterDateEnd("")
                      }
                  }}
                  slotProps={{
                      textField: {placeholder: "To"},
                      actionBar: {
                          actions: ["clear"],
                          sx: {
                              background: "#00255440",
                          },
                      },
                  }}
              />
            <FilterBarTextField
              id="input-with-icon-textfield"
              value={ filterText }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlined />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              onChange={(event) => setFilterText(event.target.value)}
            />
            <p>{`${cases.length || 0} Cases`}</p>
            <ExportCsvButton
                data={csvCases(cases)}
                fileName={"activity-report.csv"}
            >
              <FilterBarChip label={<NoteAddOutlined />} sx={{cursor: "pointer", background: '#D2F8F2'}} />
            </ExportCsvButton>
          </Stack>
        </Stack>
      </FilterBar>
    </Container>
  );
};

export default CaseFilterBar;
