import {Navigate, Route, Routes as Switch} from "react-router-dom";
import Home from "./pages/Home";
import Cases from "./pages/Cases";
import AuthenticationContext, {
    AuthenticationProvider,
} from "./context/AuthenticationContext";
import {QueryClient} from "@tanstack/react-query";
import CaseDetails from "./pages/CaseDetails";
import CreateCase from "./pages/CreateCase";
import {PersistQueryClientProvider} from "@tanstack/react-query-persist-client";
import {createSyncStoragePersister} from "@tanstack/query-sync-storage-persister";
import {CaseListFilterProvider} from "./context/CaseListFilters";
import AuditPage from "./pages/Audit";
import Auditor from "./pages/Auditor";
import FAQ from "./pages/FAQ";
import AuditLog from "./pages/AuditLog";
import {useContext} from "react";
import {JourneyContextProvider} from "./context/JourneyContext";
import VerifyDocuments from "./pages/VerifyDocuments";
import {ToastContainer} from "react-toastify";
import VerifyCase from "./pages/VerifyCase";
import FirstDayCheck from "./pages/FirstDayCheck";
import RequestOptOut from "./pages/RequestOptOut";
import PhotoMatch from "./pages/PhotoMatch";
import ReviewDocuments from "./pages/ReviewDocuments";

const persister = createSyncStoragePersister({
    storage: window.sessionStorage,
});

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: 0,
            retryDelay: 1000,
            staleTime: 0,
            cacheTime: 5 * 60 * 1000,
        },
    },
});

const RouteSwitch = () => {
    const {auditRole, isAdminUser} = useContext(AuthenticationContext);

    if (auditRole) {
        return (
            <JourneyContextProvider>
                <Switch>
                    <Route path="/" element={<Auditor/>} exact/>
                    <Route path="*" element={<Navigate to="/" replace/>}/>
                </Switch>
            </JourneyContextProvider>
        );
    }

    return (
        <JourneyContextProvider>
            <Switch>
                <Route path="/" element={<Home/>} exact/>
                <Route path="*" element={<Navigate to="/" replace/>}/>
                <Route path="/cases" element={<Cases/>}/>
                <Route path="/cases/new" element={<CreateCase/>}/>
                <Route path="/cases/:caseId" element={<CaseDetails/>} exact/>
                <Route path="/cases/:caseId/verify-documents" element={<VerifyDocuments/>} exact/>
                <Route path="/cases/:caseId/review-documents" element={<ReviewDocuments/>} exact/>
                <Route path="/cases/:caseId/verify" element={<VerifyCase/>} exact/>
                <Route path="/cases/:caseId/first-day-check" element={<FirstDayCheck/>} exact/>
                <Route path="/cases/:caseId/photo-match" element={<PhotoMatch/>} exact/>
                <Route path="/oauth/:provider" element={<Home/>} exact/>
                {isAdminUser && (
                    <>
                        <Route path="/audit/:auditType" element={<AuditPage/>}/>
                        <Route path="/audit/log" element={<AuditLog/>}/>
                        <Route path="/audit/ice" element={<AuditPage/>}/>
                    </>
                )}
                <Route path="faq" element={<FAQ/>}/>
            </Switch>
        </JourneyContextProvider>
    );
};

const Routes = () => {
    return (
        <main>
            {window.location.href.includes("opt-out") &&
                    <Switch>
                        <Route path="/opt-out/:token" element={<RequestOptOut/>} exact/>
                    </Switch>
            }
            {!window.location.href.includes("opt-out") &&
                <AuthenticationProvider>
                    <CaseListFilterProvider>
                        <PersistQueryClientProvider
                            client={queryClient}
                            persistOptions={{persister}}
                        >
                            <RouteSwitch/>
                            <ToastContainer
                                position="top-right"
                                autoClose={3000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick={false}
                                rtl={false}
                                pauseOnHover
                                theme="colored"
                            />
                        </PersistQueryClientProvider>
                    </CaseListFilterProvider>
                </AuthenticationProvider>
            }
        </main>
    );
};

export default Routes;
