import React, { createContext, useState, ReactNode } from "react";

interface FeatureFlagsContextProps {
  // eslint-disable-next-line
  features: Record<string, any>;
}

interface FeatureFlagsProviderProps {
  children: ReactNode;
}

export const FeatureFlags = createContext<FeatureFlagsContextProps>({
  features: {},
});

const mockedFeatures = {
  isI9v2: false,
  isRTWv2: false,
  isPrescient: false,
  isI9ConsumerRefEditable: false,
  isI9ScheduleCallsCalendar: false
};

export const FeatureFlagsProvider: React.FC<FeatureFlagsProviderProps> = ({
  children,
}) => {
  // eslint-disable-next-line
  const [features, setFeatures] = useState<Record<string, any>>(mockedFeatures);

  return (
    <FeatureFlags.Provider value={{ features }}>
      {children}
    </FeatureFlags.Provider>
  );
};
