import {useState} from "react";
import HeaderBar from "../../components/HeaderBar";
import {useNavigate, useParams} from "react-router-dom";
import {useCaseDetails} from "../../hooks/Cases/useCaseDetails";
import {AppBar, Button, Grid, Typography} from "@mui/material";

import DocumentList from "./DocumentList";

import {
    PrimaryButton,
    SecondaryButton,
} from "../CreateCase/CreateCaseForm/styles";
import ScheduleAppointmentModal from "../../components/CaseDetailsForm/ScheduleAppointmentModal";
import {useOAuthStatus} from "../../hooks/Meetings/useOAuthStatus";
import {useSubmitCaseEvent} from "../../hooks/Cases/useMutateCase";
import {toast} from "react-toastify";
import {ConfirmationModelContainer, useConfirmationModal} from "../../components/ConfirmationModal";
import {getDocumentImages} from "../../utils/CaseUtils/documentsDisplayUtil";

const ReviewDocuments = () => {
    const {caseId}: { caseId: string } = useParams();
    const {data, isLoading} = useCaseDetails(caseId);
    const [isScheduleAppointmentModalOpen, setScheduleAppointmentModalOpen] = useState(false);
    const {data: oauthStatus} = useOAuthStatus()

    const isSection3DocumentsSubmitted =
        data?.status && ["SECTION_3_SUBMITTED", "SECTION_3_RENAME_SUBMITTED", "SECTION_3_REHIRE_SUBMITTED"].includes(data.status);
    const isSection2DocumentsSubmitted = data?.status === "DOCUMENT_SUBMITTED";
    // eslint-disable-next-line
    const documents: any =
        // eslint-disable-next-line
        isSection3DocumentsSubmitted ? (data?.additionalData?.Section_3_Documents ?
                data?.additionalData?.Section_3_Documents[data?.additionalData?.Section_3_Documents.length - 1]?.documents : []) :
            // eslint-disable-next-line
            (isSection2DocumentsSubmitted ? (data?.additionalData?.Section_2_Documents ?
                data?.additionalData?.Section_2_Documents[data?.additionalData?.Section_2_Documents.length - 1]?.documents  : []) : []);

    // eslint-disable-next-line
    const standaloneTransactions: any =
        // eslint-disable-next-line
        isSection3DocumentsSubmitted ? (data?.additionalData?.standaloneTransactions as any)?.Section_3_Documents :
            // eslint-disable-next-line
            isSection2DocumentsSubmitted ? (data?.additionalData?.standaloneTransactions as any)?.Section_2_Documents : [];
    const sectionDocumentImages: { title: string, image: string }[]  = getDocumentImages(documents, standaloneTransactions);

    const navigate = useNavigate();
    const {mutate: submitEvent} = useSubmitCaseEvent(
        caseId,
        () => {
            toast.success("Resubmission of documents is successfully requested");
        },
        () => {
            toast.error("Resubmission of documents is failed");
        },
        () => {
            navigate(`/cases/${caseId}`)
        },
    );

    const confirmResubmitDocumentsModal = useConfirmationModal();

    const handleResubmitDocumentsRequest = () => {
        confirmResubmitDocumentsModal.openConfirmationModal({
            message: "By clicking ‘Yes’ you are requesting the employee to resubmit their documents.",
            confirmButtonLabel: "Yes",
            cancelButtonLabel: "No",
            onConfirm: () => submitEvent("RESUBMIT_DOCUMENT"),
            onCancel: () => undefined
        })
    }

    return (
        <HeaderBar breadcrumbs={[
            {
                label: "I-9 Cases",
                path: "/cases",
            },
            ...(data ? [{label: `${data.givenName} ${data.familyName}`}] : []),
            {
                label: caseId,
            },
        ]}>
            <Grid container sx={{paddingBottom: "100px"}}>
                <div>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            padding: 2,
                            borderBottom: 1,
                            borderColor: "divider",
                            display: "block",
                        }}
                    >
                        <Typography sx={{fontSize: "16px", fontWeight: "bold"}}>
                            Review & Schedule
                        </Typography>
                        <p>
                            Before scheduling a call for the Alternative Procedure, please verify the employee&quot;s
                            documents. If there is an issue, press &quot;Resubmit Documents&quot; to move the case to
                            &quot;[Section2/Supplement B] - Requested&quot; and notify the candidate to resubmit their Form I-9
                            documents.<br/><br/>
                            If everything is in order, press &quot;Schedule Appointment&quot; to select a date and time for a live
                            video call. The employee will receive a calendar invite via email.
                        </p>
                    </Grid>
                    <DocumentList images={sectionDocumentImages} isLoading={isLoading}/>
                    <AppBar
                        position="fixed"
                        sx={{
                            borderTop: 1,
                            borderColor: "divider",
                            top: "auto",
                            bottom: 0,
                            background: "white",
                        }}
                    >
                        <Grid container item xs={12} style={{flexGrow: 1, padding: 20}}>
                            <Grid item xs={8}></Grid>
                            <Grid
                                container
                                item
                                xs={4}
                                alignItems={"center"}
                                justifyContent={"flex-end"}>
                                <Button sx={{textDecoration: "underline", textTransform: "none"}} href={`/cases/${caseId}`}>
                                    Exit, no decision
                                </Button>
                                <SecondaryButton variant="contained"
                                                 onClick={handleResubmitDocumentsRequest}>
                                    Resubmit Documents
                                </SecondaryButton>
                                <PrimaryButton
                                    onClick={() => setScheduleAppointmentModalOpen(!isScheduleAppointmentModalOpen)}>
                                    Schedule Appointment
                                </PrimaryButton>
                            </Grid>
                        </Grid>
                    </AppBar>
                </div>
            </Grid>
            <ScheduleAppointmentModal isOpen={isScheduleAppointmentModalOpen}
                                      onClose={() => setScheduleAppointmentModalOpen(!isScheduleAppointmentModalOpen)}
                                      caseId={caseId as string}
                                      startDate={data?.startDate as string}
                                      section2Deadline={data?.additionalData?.i9_section_2_deadline as string}
                                      isAuthenticated={!!oauthStatus?.authorised}
                                      isReschedule={false}
                                      candidatename={data?.givenName + ' ' + data?.familyName}
            />
            <ConfirmationModelContainer {...confirmResubmitDocumentsModal} />
        </HeaderBar>
    );
};

export default ReviewDocuments;
