import React, {Dispatch, SetStateAction} from 'react';
import {Dialog, DialogContent, DialogTitle, Grid} from "@mui/material";
import {Close} from "@mui/icons-material";

const DocumentImageModal = ({imageName, imageScr, open, setOpen}:
                                {
                                    imageName?: string,
                                    imageScr?: string | undefined,
                                    open: boolean,
                                    setOpen: Dispatch<SetStateAction<boolean>>
                                }) => {

    const closeButtonHandler = () => {
        setOpen(false);
    }

    return (
        <Dialog maxWidth={'md'}
                open={open}
                fullWidth>
            <DialogTitle>
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <div style={{
                        height: 0,
                        paddingRight: 15,
                        paddingTop: 5
                    }}>
                        <p style={{ margin: 0 }} onClick={closeButtonHandler}>
                            <Close fontSize="small" />
                        </p>
                    </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'flex-start'}}>
                    <div style={{
                        marginLeft: 30,
                        fontSize: 16,
                        textTransform: 'uppercase'
                    }}>
                        <strong>{imageName}</strong>
                    </div>
                </div>
            </DialogTitle>
            <DialogContent>
                <Grid container justifyContent={"flex-start"}>
                    {
                        imageScr && <Grid item xs={12}>
                            <div style={{
                                padding: "16.5px 0px",
                                borderRadius: 6,
                                position: "relative",
                                width: "100%",
                            }}>
                                <div style={{
                                    width: "100%",
                                }}>
                                    <img
                                        src={imageScr}
                                        style={{display: "block", width: "100%"}}
                                        aria-label={imageName}
                                    />
                                </div>
                            </div>
                        </Grid>
                    }
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default DocumentImageModal;
