import React, {FC, forwardRef} from "react";
import {Checkbox, CircularProgress, Grid, SvgIcon, TextField} from "@mui/material";
import {ReactComponent as CorrectionIcon} from "../../img/correction-icon.svg";
import {ReactComponent as CompareIcon} from "../../img/compare-icon.svg";
import {StartEndDateField} from "./BasicDetailsPanel/styles";
import dayjs from "dayjs";
import {CompareButton, EditButton} from './styles'
import {Control} from "react-hook-form/dist/types/form";
import {Controller} from "react-hook-form";
import DocumentImage from "../DocumentImage";

type DetailsBlockProps = {
    title?: string;
    xs: number;
    borderRight?: boolean;
    borderBottom?: boolean;
    alignItems?: string;
    justifyContent?: string;
    children: string | JSX.Element | (JSX.Element | false | null)[];
    compareButton?: boolean;
    compareButtonClick?: () => void;
    editButton?: boolean;
    editButtonClick?: () => void;
}

export const DetailsBlock: FC<DetailsBlockProps> = ({
                                                        title,
                                                        xs,
                                                        borderRight,
                                                        borderBottom,
                                                        children,
                                                        compareButton,
                                                        compareButtonClick,
                                                        editButton,
                                                        editButtonClick,
                                                        ...props
                                                    }) => {
    return <Grid
        container
        item
        xs={xs}
        p={2}
        sx={{
            borderRight: borderRight ? "1px solid #D7D7D7" : "none",
            borderBottom: borderBottom ? "1px solid #D7D7D7" : "none",
        }}
        {...props}
    >
        {
            title && <Grid item xs={9} style={{fontWeight: 600, marginBottom: 10,}}>{title}</Grid>
        }
        {
            compareButton && <Grid item xs={3} alignContent={'flex-end'}>
                <CompareButton variant="contained" disableElevation startIcon={<CompareIcon/>} onClick={compareButtonClick}>
                    Compare Documents
                </CompareButton>
            </Grid>
        }
        {
            editButton && <Grid item xs={3} alignContent={'flex-end'}>
                <EditButton variant="contained" disableElevation onClick={editButtonClick}>
                    Edit
                </EditButton>
            </Grid>
        }
        {children}
    </Grid>;
}

export const StatusLabel: FC<{ backgroundColor: string, borderColor: string, label: string, visible?: boolean }> = ({
                                                                                                                        backgroundColor,
                                                                                                                        borderColor,
                                                                                                                        label,
                                                                                                                        visible
                                                                                                                    }) => {
    return <div style={{
        background: `linear-gradient(0deg, ${backgroundColor}, ${backgroundColor}), #FFFFFF`,
        border: `1px solid ${borderColor}`,
        borderRadius: "25px",
        width: "100%",
        textAlign: "center",
        fontSize: "16px",
        color: "#252525",
        padding: "10px",
        display: `${visible ? 'block' : 'none'}`
    }}>{label}</div>;
}

const ImageField: FC<{ useImageQuery: () => ImageQuery, path: string, alt: string }> = ({useImageQuery, path, alt}) => {
    const {isSuccess, isError, isLoading, data} = useImageQuery();

    return <TextField
        fullWidth
        size={"small"}
        value={isError ? `unable to load: ${path}` : ""}
        InputProps={{
            readOnly: true,
            startAdornment:
                isSuccess ?
                    <img style={{height: 38}} src={data} alt={alt}/>
                    : isLoading ?
                        <div style={{zoom: 0.6, display: "flex"}}><CircularProgress color={"secondary"}/></div>
                        : undefined,
        }}
    />;
}

export const ImageBlock: FC<{ useImageQuery: () => ImageQuery, path: string, alt: string }> = ({useImageQuery, path, alt}) => {
    const {isError, isLoading, data} = useImageQuery();

    return isLoading ? <CircularProgress color={"secondary"}/> :
        isError ? <TextField
            fullWidth
            size={"small"}
            value={`unable to load: ${path}`}
            InputProps={{readOnly: true}}
        /> :
    <DocumentImage imageScr={data} imageName={alt} showImageName={false}/>
}

export type ImageQuery = {
    isSuccess: boolean;
    isLoading: boolean;
    isError: boolean;
    data: string;
}

export type EditableFieldProps = {
    value?: string;
    type: "text" | "date" | string;
    handleChange?: (value: string) => void;
}

type DetailsFieldProp = {
    label: string;
    required?: boolean;
    value?: string;
    field?: string;
    isCheckBoxVisible?: boolean;
    image?: () => ImageQuery;
    xs?: number;
    editableProps?: EditableFieldProps,
}

// eslint-disable-next-line
const RenderHTML = forwardRef((props: any, ref) =>
    (<div
        className={`MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputSizeSmall Mui-readOnly MuiInputBase-readOnly`}
        style={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            font: "inherit",
            letterSpacing: "inherit",
            color: "currentColor",
            border: 0,
            boxSizing: "content-box",
            background: "none",
            height: "1.4375em",
            margin: 0,
            display: "block",
            minWidth: 0,
            width: "100%",
            padding: "8.5px 14px",
        }}
        dangerouslySetInnerHTML={{__html: props.value}}></div>));
RenderHTML.displayName = 'RenderHTML';

const ControlledTextField = (params: { item: DetailsFieldProp, control: Control }) => {
    return <Controller
        // eslint-disable-next-line
        //@ts-ignore
        name={params.item.field}
        control={params.control}
        rules={{required: true}}
        render={({
                     field,
                     fieldState: {error},
                 }) => (
            <TextField
                {...field}
                fullWidth
                size={"small"}
                error={!!error}
                helperText={error?.message}
            />
        )}
    />
}

const ControlledDateField = (params: { item: DetailsFieldProp, control: Control }) => {
    return <Controller
        // eslint-disable-next-line
        //@ts-ignore
        name={params.item.field}
        control={params.control}
        rules={{required: true}}
        render={({
                     field,
                     fieldState: {error},
                 }) => (
            <><StartEndDateField
                {...field}
                value={field.value? dayjs(field.value) : undefined}
                slotProps={{
                    actionBar: {
                        actions: ["clear"],
                        sx: {
                            button: {
                                color: "white",
                            },
                            background: "black",
                        },
                    },
                    textField: {
                        error: !!error,
                        helperText: error?.message,
                    },
                }}
            /></>
        )}
    />
}

const EditableTextField = (params: { item: DetailsFieldProp }) => {
    return <TextField
        fullWidth
        size={"small"}
        value={params.item.editableProps?.value}
        onChange={evt => {
            params.item.editableProps?.handleChange?.(evt.target.value)
        }}
        sx={{backgroundColor: "#EDF1F8"}}
        InputProps={{
            style: {paddingRight: "35px"}
        }}
    />
}

const EditableDateField = (params: { item: DetailsFieldProp }) => {
    return <StartEndDateField
        value={(!params.item?.editableProps?.value || ["", "n/a", "N/A"].includes(params.item?.editableProps.value)) ? null : dayjs(params.item.editableProps.value)}
        onChange={(date: unknown) => {
            if (date) {
                params.item.editableProps?.handleChange?.(new Date(
                    new Date(date as Date)
                    .toLocaleString("en-US")
                ).toISOString());
            } else {
                params.item.editableProps?.handleChange?.("N/A");
            }
        }}
        slotProps={{
            actionBar: {
                actions: ["clear"],
                sx: {
                    button: {
                        color: "white",
                    },
                    background: "black",
                },
            },
        }}
    />
}

export const DetailsFields: FC<{
    keyPrefix?: string, items: DetailsFieldProp[],
    handleItemSelected?: (field: string) => void,
    showLipstick?: (field: string) => boolean,
    isCorrectingField?: (field: string) => boolean,
    selectedFields?: string[],
    compactView?: boolean,
    // eslint-disable-next-line
    control?: Control<any>;
}> =
    ({
         keyPrefix, items, handleItemSelected, showLipstick, isCorrectingField, selectedFields,
         compactView, control
     }) => {
        return <>
            {
                items.map(item => <Grid key={keyPrefix + item.label} container alignItems={"center"} item xs={12}
                                        sx={{marginTop: 2}}>
                    {!compactView && <Grid item xs={6}>{item.required? item.label + "*" : item.label}</Grid>}
                    <Grid item xs={item.xs || (compactView ? 12 : 6)} style={{paddingLeft: "40px"}}>
                        {compactView && <div>{item.required? item.label + "*" : item.label}</div>}
                        {item.image  && item.value ? <ImageField
                            useImageQuery={item.image}
                            path={item.value}
                            alt={keyPrefix + item.label + "-image"}
                        /> : (item.field && !item.editableProps) ?
                            <div style={{position: "relative"}}>
                                <SvgIcon style={{
                                    fontSize: 24, position: "absolute", margin: "8px", left: "-40px",
                                    display: showLipstick && showLipstick(item.field) ? "" : "none"
                                }}>
                                    <CorrectionIcon/>
                                </SvgIcon>
                                <TextField
                                    fullWidth
                                    value={item.value}
                                    size={"small"}
                                    InputProps={{
                                        inputComponent: RenderHTML,
                                        readOnly: true,
                                        style: {
                                            paddingRight: item.isCheckBoxVisible ? "35px" : "0px",
                                            backgroundColor: isCorrectingField && isCorrectingField(item.field) ? "rgba(235,231,144, 0.6)" : "white"
                                        }
                                    }}
                                />
                                <Checkbox
                                    style={{
                                        position: "absolute", right: "5px",
                                        display: item.isCheckBoxVisible ? "" : "none"
                                    }}
                                    checked={selectedFields?.includes(item.field)}
                                    onClick={() => {
                                        if (item.field && handleItemSelected) {
                                            handleItemSelected(item.field);
                                        }
                                    }}
                                    color={"default"}
                                />
                            </div> :
                            item.editableProps?.type === "text" ?
                                (control && item.field ? <ControlledTextField item={item}
                                                                              control={control}/>
                                    : <EditableTextField item={item}/>)
                                : item.editableProps?.type === "date" ?
                                    (control && item.field ? <ControlledDateField item={item}
                                                                                  control={control}/>
                                        : <EditableDateField item={item}/>)
                                    : <TextField
                                        fullWidth
                                        size={"small"}
                                        value={item.value}
                                        InputProps={{
                                            readOnly: true,
                                            style: {paddingRight: "35px"}
                                        }}
                                    />
                        }</Grid>
                </Grid>)
            }
        </>
    }
