import {useContext, useState} from "react";
import HeaderBar from "../../components/HeaderBar";
import {useNavigate, useParams} from "react-router-dom";
import {useCaseDetails} from "../../hooks/Cases/useCaseDetails";
import {AppBar, Button, Grid, Stack} from "@mui/material";

import VideoCallData from "./VideoCallData";
import DocumentList from "./DocumentList";
import ReferModal from "./ReferModal";

import {
    PrimaryButton,
    SecondaryButton,
} from "../CreateCase/CreateCaseForm/styles";
import {TopTabLeftButton, TopTabRightButton} from "./styles";
import InstructionsBar from "./InstructionsBar";
import RejectDocumentsModal from "./RejectDocumentsModal";
import CompleteSectionTwo from "../CompleteSectionTwo";
import CompleteSectionThree from "../CompleteSectionThree";
import JourneyContext from "../../context/JourneyContext";
import {MeetingMap} from "../../models/responses/Cases/CaseResponse";
import {useSubmitCaseEvent} from "../../hooks/Cases/useMutateCase";
import {toast} from "react-toastify";
import {ConfirmationModelContainer, useConfirmationModal} from "../../components/ConfirmationModal";
import {getDocumentImages} from "../../utils/CaseUtils/documentsDisplayUtil";

const VerifyDocuments = () => {
    const {caseId}: { caseId: string } = useParams();
    const {data, isLoading} = useCaseDetails(caseId);
    const [modalOpen, setModalOpen] = useState<"REJECT" | "REFER" | null>(null);
    const [isCompleteSectionInProgress, setCompleteSectionInProgress] = useState(false);

    const {currentJourney} = useContext(JourneyContext);
    const isI9 = currentJourney?.journeyType.startsWith("I9");
    const isPrescient = currentJourney?.journeyType === "PRESCIENT";

    const isSection3DocumentsSubmitted =
        data?.status && ["SECTION_3_SUBMITTED", "SECTION_3_RENAME_SUBMITTED", "SECTION_3_REHIRE_SUBMITTED"].includes(data.status);
    const isSection2DocumentsSubmitted = data?.status === "DOCUMENT_SUBMITTED";
    // eslint-disable-next-line
    const documents: any =
        // eslint-disable-next-line
        isSection3DocumentsSubmitted ? (data?.additionalData?.Section_3_Documents ?
                data?.additionalData?.Section_3_Documents[data?.additionalData?.Section_3_Documents.length - 1]?.documents : []) :
            // eslint-disable-next-line
            (isSection2DocumentsSubmitted ? (data?.additionalData?.Section_2_Documents ?
                data?.additionalData?.Section_2_Documents[data?.additionalData?.Section_2_Documents.length - 1]?.documents : []) : []);

    // eslint-disable-next-line
    const standaloneTransactions: any =
        // eslint-disable-next-line
        isSection3DocumentsSubmitted ? (data?.additionalData?.standaloneTransactions as any)?.Section_3_Documents :
            // eslint-disable-next-line
            isSection2DocumentsSubmitted ? (data?.additionalData?.standaloneTransactions as any)?.Section_2_Documents : [];

    const sectionDocumentImages: { title: string, image: string }[] = getDocumentImages(documents, standaloneTransactions);

    const navigate = useNavigate();
    const {mutate: submitEvent} = useSubmitCaseEvent(
        caseId,
        () => {
            toast.success("Resubmission of documents is successfully requested");
        },
        () => {
            toast.error("Resubmission of documents is failed");
        },
        () => {
            navigate(`/cases/${caseId}`)
        },
    );

    const modal = useConfirmationModal();

    const handleResubmitDocumentsRequest = () => {
        modal.openConfirmationModal({
            message: "By clicking ‘Yes’ you are requesting the employee to resubmit their documents.",
            confirmButtonLabel: "Yes",
            cancelButtonLabel: "No",
            onConfirm: () => submitEvent("RESUBMIT_DOCUMENT"),
            onCancel: () => undefined
        })
    }

    return (
        <HeaderBar
            breadcrumbs={[
                {
                    label: isI9 ? "I-9 Cases" : (isPrescient ? "myKYC Cases" : "myRTW Cases"),
                    path: "/cases",
                },
                ...(data ? [{label: `${data.givenName} ${data.familyName}`}] : []),
                {
                    label: caseId,
                },
            ]}
        >
            <Grid container sx={{paddingBottom: "100px"}}>
                <Grid
                    alignItems={"center"}
                    item
                    xs={12}
                    sx={{borderBottom: 1, borderColor: "divider", padding: 2}}
                >
                    <Stack direction="row" spacing={2}>
                        <TopTabLeftButton className={isCompleteSectionInProgress ? "" : "TopTabSelected"}>Verify
                            Documentation</TopTabLeftButton>
                        <TopTabRightButton className={isCompleteSectionInProgress ? "TopTabSelected" : ""}>
                            {isSection3DocumentsSubmitted ? "Complete Supplement B" :
                                isSection2DocumentsSubmitted ? "Complete Section 2" : ""}
                        </TopTabRightButton>
                        <SecondaryButton
                            variant="contained"
                            style={{marginLeft: "auto"}}
                            onClick={handleResubmitDocumentsRequest}>
                            Resubmit Documents
                        </SecondaryButton>
                    </Stack>
                </Grid>
                {!isCompleteSectionInProgress && <div>
                    <VideoCallData
                        meetingUrl={data && (data.additionalData?.meeting as MeetingMap)?.[data.status]?.hostUrl}/>
                    <DocumentList images={sectionDocumentImages} isLoading={isLoading}/>
                    <InstructionsBar/>
                    <AppBar
                        position="fixed"
                        sx={{
                            borderTop: 1,
                            borderColor: "divider",
                            top: "auto",
                            bottom: 0,
                            background: "white",
                        }}
                    >
                        <Grid container item xs={12} style={{flexGrow: 1, padding: 20}}>
                            <Grid item xs={8}></Grid>
                            <Grid
                                container
                                item
                                xs={4}
                                alignItems={"center"}
                                justifyContent={"flex-end"}
                            >
                                <Button sx={{textDecoration: "underline", textTransform: "none"}}
                                        href={`/cases/${caseId}`}>
                                    Exit, no decision
                                </Button>
                                <SecondaryButton
                                    variant="contained"
                                    onClick={() => setModalOpen("REFER")}>
                                    Refer
                                </SecondaryButton>
                                <PrimaryButton
                                    variant="contained"
                                    onClick={() => setModalOpen("REJECT")}
                                >
                                    Reject
                                </PrimaryButton>
                                <PrimaryButton onClick={() => setCompleteSectionInProgress(true)}>
                                    Verify Documentation
                                </PrimaryButton>
                            </Grid>
                        </Grid>
                    </AppBar>
                </div>}
                {isCompleteSectionInProgress && isSection2DocumentsSubmitted && <CompleteSectionTwo
                    onReject={() => setModalOpen("REJECT")}></CompleteSectionTwo>}
                {isCompleteSectionInProgress && isSection3DocumentsSubmitted && <CompleteSectionThree
                    onReject={() => setModalOpen("REJECT")}></CompleteSectionThree>}
            </Grid>
            {
                modalOpen === "REJECT" ? <RejectDocumentsModal
                    onClose={() => setModalOpen(null)}
                    caseId={caseId}
                /> : modalOpen === "REFER" ? <ReferModal
                    onClose={() => setModalOpen(null)}
                    caseId={caseId}
                /> : null
            }
            <ConfirmationModelContainer {...modal} />
        </HeaderBar>
    );
};

export default VerifyDocuments;
