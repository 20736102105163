import * as yup from "yup";

export  const sectionThreeDataSchema = yup.object().shape({
  employer_full_name: yup.string().required("Please fill the required field to continue."),
  employee_last_name: yup.string().notRequired(),
  employee_first_name: yup.string().notRequired(),
  employee_middle_initial: yup.string().notRequired(),
  documents: yup.array(
      yup.object({
        document_title: yup.string().required("Please fill the required field to continue."),
        document_number: yup.string().required("Please fill the required field to continue."),
        uscis_number: yup.string().notRequired(),
        issuing_authority: yup.string().required("Please fill the required field to continue."),
        list_type: yup.string().required(),
        document_type: yup.string().required(),
        date_of_expiry: yup.date().nullable()
            .min(new Date(1900, 1, 1), "Please enter a valid date")
            .max(new Date(3000, 1, 1), "Please enter a valid date")
      })),
  signature: yup.object({
    image: yup.string().required("Please fill the required field to continue."),
  }),
  consent: yup.boolean().isTrue("You need to agree to sign electronically to be able to proceed.")
      .required("You need to agree to sign electronically to be able to proceed.")
});

export  const sectionThreeRenameDataSchema = yup.object().shape({
    employee_last_name: yup.string().required("Please fill the required field to continue."),
    employee_first_name: yup.string().required("Please fill the required field to continue."),
    employee_middle_initial: yup.string().required("Please fill the required field to continue."),
    employer_full_name: yup.string().required("Please fill the required field to continue."),
    documents: yup.array(
        yup.object({
            document_title: yup.string().required("Please fill the required field to continue."),
            document_number: yup.string().required("Please fill the required field to continue."),
            uscis_number: yup.string().notRequired(),
            issuing_authority: yup.string().required("Please fill the required field to continue."),
            list_type: yup.string().required(),
            document_type: yup.string().required(),
            date_of_expiry: yup.date().nullable()
                .min(new Date(1900, 1, 1), "Please enter a valid date")
                .max(new Date(3000, 1, 1), "Please enter a valid date")
        })),
    signature: yup.object({
        image: yup.string().required("Please fill the required field to continue."),
    }),
    consent: yup.boolean().isTrue("You need to agree to sign electronically to be able to proceed.")
        .required("You need to agree to sign electronically to be able to proceed.")
});

export  const sectionThreeRehireDataSchema = yup.object().shape({
    employer_full_name: yup.string().required("Please fill the required field to continue."),
    employee_last_name: yup.string().notRequired(),
    employee_first_name: yup.string().notRequired(),
    employee_middle_initial: yup.string().notRequired(),
    rehire_date: yup.date().required("Please fill the required field to continue.")
        .min(new Date(1900, 1, 1), "Please enter a valid date")
        .max(new Date(3000, 1, 1), "Please enter a valid date"),
    documents: yup.array(
        yup.object({
            document_title: yup.string().required("Please fill the required field to continue."),
            document_number: yup.string().required("Please fill the required field to continue."),
            uscis_number: yup.string().notRequired(),
            issuing_authority: yup.string().required("Please fill the required field to continue."),
            list_type: yup.string().required(),
            document_type: yup.string().required(),
            date_of_expiry: yup.date().nullable()
                .min(new Date(1900, 1, 1), "Please enter a valid date")
                .max(new Date(3000, 1, 1), "Please enter a valid date")
        })),
    signature: yup.object({
        image: yup.string().required("Please fill the required field to continue."),
    }),
    consent: yup.boolean().isTrue("You need to agree to sign electronically to be able to proceed.")
        .required("You need to agree to sign electronically to be able to proceed.")
});